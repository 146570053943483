<template>
  <div class="video-box">
    <div class="video-cont">
      <div class="grid">
        <div class="line">
          <div class="left">
            <div v-for="item in [0, 1, 2, 3, 4, 5]" :key="item" class="item">
              <div class="inner">
                <div class="cont-box">
                  <div class="cont" :ref="`users-${item}`"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="item">
              <div class="inner">
                <div class="cont-box">
                  <div class="cont" ref="users-16"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="normal">
          <div
            v-for="item in [6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
            :key="item"
            class="item"
          >
            <div class="inner">
              <div class="cont-box">
                <div class="cont" :ref="`users-${item}`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="control">
        <Tooltip content="mute video" placement="top">
          <div
            @click="toggleVideo()"
            :class="['icon-box', { disable: mute.video }]"
          >
            <div v-if="mute.video" class="mute"></div>
            <Icon class="icon" type="ios-videocam-outline" />
          </div>
        </Tooltip>

        <Tooltip content="mute audio" placement="top">
          <div
            @click="toggleAudio()"
            :class="['icon-box', { disable: mute.audio }]"
          >
            <div v-if="mute.audio" class="mute"></div>
            <Icon class="icon" type="ios-mic-outline" />
          </div>
        </Tooltip>

        <Tooltip content="exit" placement="top">
          <div @click="leave()" class="icon-box">
            <Icon class="icon" type="ios-exit-outline" /></div
        ></Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
const { _queryTwilioToken } = api;

let localTracksOutside = null;
let roomOutside = null;

export default {
  computed: {
    ...mapState("user", ["user_id"])
  },
  created() {
    const channel = this.$route.query.channel;
    this.channel = channel;

    window.Twilio.Video.createLocalTracks().then(localTracks => {
      localTracksOutside = localTracks;

      localTracks.forEach(localTrack => {
        localTrack.disable();
        if (localTrack.kind === "video") {
          this.$refs[`users-16`].appendChild(localTrack.attach());
        }
      });

      _queryTwilioToken({
        type: "video",
        room_name: channel
      }).then(res => {
        window.Twilio.Video.connect(res.data.token, {
          tracks: localTracks
        }).then(room => {
          roomOutside = room;

          const getPlaceIndex = () => {
            for (let i = 0; i < 16; i++) {
              if (this.users[i] === null) {
                return i;
              }
            }
          };

          const participantConnected = participant => {
            console.log('Participant "%s" connected', participant.identity);

            const i = getPlaceIndex();
            this.users[i] = {
              index: i,
              sid: participant.sid
            };

            const $container = this.$refs[`users-${i}`][0];

            participant.on("trackSubscribed", track => {
              $container.appendChild(track.attach());
            });
          };

          const participantDisconnected = participant => {
            console.log("participantDisconnected");
            const user = this.users.find(
              user => user && user.sid === participant.sid
            );

            if (user) {
              this.users[user.index] = null;
              const $container = this.$refs[`users-${user.index}`][0];

              while ($container.firstChild) {
                $container.removeChild($container.firstChild);
              }
            }
          };

          room.participants.forEach(participantConnected);
          room.on("participantConnected", participantConnected);
          room.on("participantDisconnected", participantDisconnected);
        });
      });
    });
  },

  data() {
    const users = [];
    for (let i = 0; i < 16; i++) {
      users.push(null);
    }
    return {
      mute: { audio: true, video: true },
      users
    };
  },

  methods: {
    toggleVideo() {
      if (localTracksOutside) {
        localTracksOutside.forEach(item => {
          if (item.kind === "video") {
            if (this.mute.video) {
              this.mute.video = false;
              item.enable();
            } else {
              this.mute.video = true;
              item.disable();
            }
          }
        });
      }
    },

    toggleAudio() {
      if (localTracksOutside) {
        localTracksOutside.forEach(item => {
          if (item.kind === "audio") {
            if (this.mute.audio) {
              this.mute.audio = false;
              item.enable();
            } else {
              this.mute.audio = true;
              item.disable();
            }
          }
        });
      }
    },
    leave() {
      if (roomOutside) {
        roomOutside.disconnect();
      }
      window.close();
    }
  },

  beforeDestroy() {
    this.leave();
  }
};
</script>

<style lang="less" scoped>
.video-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-cont {
    width: 80%;
    .grid {
      .normal {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 20%;
          .inner {
            width: 100%;
            height: 0;
            padding-top: 56.25%;
            position: relative;
            .cont-box {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              padding: 3px;
              .cont {
                width: 100%;
                height: 100%;
                background: #eee;
                /deep/ video {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }
            }
          }
        }
      }
      .line {
        width: 100%;
        display: flex;
        .left {
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          .item {
            width: 33.33333%;
            .inner {
              width: 100%;
              height: 0;
              padding-top: 56.25%;
              position: relative;
              .cont-box {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                padding: 3px;
                .cont {
                  width: 100%;
                  height: 100%;
                  background: #eee;
                  /deep/ video {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }
            }
          }
        }
        .right {
          width: 40%;
          .item {
            width: 100%;
            .inner {
              width: 100%;
              height: 0;
              padding-top: 56.25%;
              position: relative;
              .cont-box {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                padding: 3px;
                .cont {
                  width: 100%;
                  height: 100%;
                  background: #eee;
                  /deep/ video {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .control {
      position: fixed;
      bottom: 40px;
      display: flex;
      width: 200px;
      align-items: center;
      justify-content: space-around;
      left: 50%;
      transform: translateX(-50%);
      .icon-box {
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.disable {
          .icon {
            color: #ccc;
          }
        }
        .mute {
          width: 40px;
          height: 2px;
          background: #ff4d4f;
          position: absolute;
          transform: rotate(-45deg);
        }
        .icon {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          padding: 12px;
        }
      }
    }
  }
}
</style>
